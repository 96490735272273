import {NavigateHome, router} from '@/router';
import {isHmr} from '@/utils';
import {type Contact, type Customer, type CustomerApi} from '@apispec/customer';
import {type GenerationApi, type SSOInteractionApi, type TokenRequest, type ValidationApi} from '@apispec/token';
import {type AppRoles} from '@plugins/auth/AppRoles';
import {type AppConfiguration} from '@plugins/config/AppConfiguration';
import {type AppEmitter} from '@plugins/eventbus/eventbus';
import * as Sentry from '@sentry/vue';
import {type AxiosInstance} from 'axios';
import {jwtDecode} from 'jwt-decode';
import {Log as OidcLog, OidcClient} from 'oidc-client-ts';
import {computed, type ComputedRef, ref, type Ref} from 'vue';
import {type ValidationError} from '../api/api';
import {getResponseObject} from '../http/httpTools';
import {getFromLocalStorage, removeFromLocalStorage, setInLocalStorage} from '../localStorage';
import {type ModalInterface} from '../modal/modal';

const tokenStorageKey = 'app_auth_token';
const ssoIdTokenHintStorageKey = 'app_sso_id_token_hint';

interface AuthenticatorOptions {
  defaultHttp: AxiosInstance;
  eventBus: AppEmitter;
  modals: ModalInterface;
  appConfiguration: AppConfiguration;
}

interface TokenData {
  uid: number; // User id
  // eslint-disable-next-line camelcase
  uid_g: string; // User guid
  cid: string; // Customer guid
  username: string;
  loyalty: boolean;
  exp: number;
  roles?: AppRoles[];
  external_auth?: boolean;
}

const TOKEN_LIFETIME = 15 * 60; // 15 minutes in seconds
const REFRESH_INTERVAL = 60 * 1000; // 1 minute in milliseconds
const TOKEN_AGE_BEFORE_REFRESH = 5 * 60 * 1000; // 5 minutes in milliseconds

export default class Authenticator {
  private eventBus: AppEmitter;
  private modals: ModalInterface;
  private appConfiguration: AppConfiguration;

  private ssoInteractionApi: (() => SSOInteractionApi) | null = null;
  private generationApi: (() => GenerationApi) | null = null;
  private validationApi: (() => ValidationApi) | null = null;
  private customerApi: (() => CustomerApi) | null = null;

  private token: Ref<string | null> = ref(null);
  private tokenData: Ref<TokenData | null> = ref(null);
  private accountData: Ref<Customer | null> = ref(null);
  private initDone = ref(false);

  private userRoles = computed<Record<AppRoles, boolean>>(() => {
    const result: Record<AppRoles, boolean> = {
      RESELLER_ADMIN: false,
      RESELLER_FINANCE: false,
      RESELLER_SERVICE_DESK: false,
      RESELLER_ORDER: false,
    };

    (this.tokenData.value?.roles || []).forEach((r) => {
      result[r] = true;
    });

    return result;
  });

  private refreshTokenInterval: number | null = null;

  private ssoIdTokenHint: string | null = null;

  constructor(options: AuthenticatorOptions) {
    this.eventBus = options.eventBus;
    this.modals = options.modals;
    this.appConfiguration = options.appConfiguration;

    // Install default authentication handler
    this.addHttpAuth(options.defaultHttp);
  }

  public loadApis(
    ssoInteractionApi: () => SSOInteractionApi,
    generationApi: () => GenerationApi,
    validationApi: () => ValidationApi,
    customerApi: () => CustomerApi): void {
    this.ssoInteractionApi = ssoInteractionApi;
    this.generationApi = generationApi;
    this.validationApi = validationApi;
    this.customerApi = customerApi;
  }

  public async loadCurrentAuth(): Promise<void> {
    const token = getFromLocalStorage(tokenStorageKey);
    if (!token) {
      this.initDone.value = true;
      return;
    }

    await this.loadAccountData(token, true);
    this.ssoIdTokenHint = getFromLocalStorage(ssoIdTokenHintStorageKey);
    this.initDone.value = true;
  }

  private getSsoClient(): OidcClient {
    if (isHmr()) {
      OidcLog.setLogger(console);
      OidcLog.setLevel(OidcLog.DEBUG);
    }

    const login = document.createElement('a');
    login.href = router.resolve({name: 'sso_login'}).href;
    const logout = document.createElement('a');
    logout.href = router.resolve({name: 'logout'}).href;

    return new OidcClient({
      redirect_uri: login.href,
      post_logout_redirect_uri: logout.href,
      ...this.appConfiguration.getSsoDetails()
    });
  }

  public async generateSsoAuthenticationRedirect(): Promise<string> {
    this.clearAccountData();

    return (await this
      .getSsoClient()
      .createSigninRequest({}))
      .url;
  }

  public async generateSsoSignOut(): Promise<string> {
    return (await this
      .getSsoClient()
      .createSignoutRequest({
        id_token_hint: this.ssoIdTokenHint ?? undefined,
      })).url;
  }

  public async processSsoAuthentication(url: string): Promise<void> {
    this.clearAccountData();

    try {
      const ssoResponse = await this.getSsoClient().processSigninResponse(url);
      this.ssoIdTokenHint = ssoResponse.id_token ?? null;
      if (this.ssoIdTokenHint) {
        setInLocalStorage(ssoIdTokenHintStorageKey, this.ssoIdTokenHint);
      } else {
        removeFromLocalStorage(ssoIdTokenHintStorageKey);
      }

      const response = await getResponseObject(async () => {
        if (!this.ssoInteractionApi) {
          throw new Error('SSO interaction API not available!');
        }

        return await this.ssoInteractionApi().exchangeToken({
          exchangeTokenRequest: {
            token: ssoResponse.access_token,
          },
        }, {
          headers: {
            // This endpoint requires this to be set explicitly
            Accept: 'application/json',
            // Set explicit token lifetime
            'X-Token-Lifetime': TOKEN_LIFETIME,
            'X-Token-Include-Roles': true,
          },
        });
      }, {
        forbiddenCallback: () => {
          // Ignore this error
        },
      });

      if (response) {
        await this.loadAccountData(response.token);
      }
    } catch (e) {
      // Clear account on error
      this.clearAccountData();

      throw e;
    }
  }

  public async tryAuthenticate(authData: TokenRequest, apiErrors: Ref<ValidationError[]>): Promise<boolean> {
    this.clearAccountData();

    try {
      // Try to authenticate
      const response = await getResponseObject(async () => {
        if (!this.generationApi) {
          throw new Error('Token API not available!');
        }

        return await this.generationApi().generateToken({
          tokenRequest: authData,
        }, {
          headers: {
            // This endpoint requires this to be set explicitly
            Accept: 'application/json',
            // Set explicit token lifetime
            'X-Token-Lifetime': TOKEN_LIFETIME,
            'X-Token-Include-Roles': true,
          },
        });
      }, {
        apiErrors,
        forbiddenCallback: () => {
          // Ignore this error
        },
      });

      if (response) {
        await this.loadAccountData(response.token);
      }
    } catch (e) {
      // Clear account on error
      this.clearAccountData();

      throw e;
    }

    return this.isAuthenticated.value;
  }

  public addHttpAuth(axiosInstance: AxiosInstance): void {
    axiosInstance.interceptors.request.use(config => {
      if (this.token.value) {
        config.headers.Authorization = 'Bearer ' + this.token.value;
      }

      return config;
    });
  }

  public async loadExternalToken(token: string | null): Promise<boolean> {
    this.clearAccountData();
    if (!token) {
      return false;
    }

    this.token.value = token;
    setInLocalStorage(tokenStorageKey, this.token.value);
    return await this.tryRefresh(true, true, false);
  }

  public async logout(): Promise<void> {
    if (this.ssoIdTokenHint || this.externalAuthBased.value) {
      const url = await this.generateSsoSignOut();
      return new Promise(() => {
        this.clearAccountData();
        removeFromLocalStorage(ssoIdTokenHintStorageKey);
        window.location.href = url;
      });
    } else {
      this.clearAccountData();
      return Promise.resolve();
    }
  }

  public updateCustomerAddress(data: Customer): void {
    if (!this.accountData.value) {
      return;
    }

    this.accountData.value.address = data.address;
  }

  public updateCustomerBankingDetails(data: Customer): void {
    if (!this.accountData.value) {
      return;
    }

    this.accountData.value.iban = data.iban;
    this.accountData.value.bic = data.bic;
  }

  public updateCustomerContact(type: string, contact?: Contact): void {
    if (!this.accountData.value) {
      return;
    }

    switch (type) {
      case 'billing':
        this.accountData.value.billingContact = contact;
        break;
      case 'order':
        this.accountData.value.orderContact = contact;
        break;
      case 'abuse':
        this.accountData.value.abuseContact = contact;
        break;
      case 'support':
        this.accountData.value.supportContact = contact;
        break;
      default:
        if (contact) {
          this.accountData.value.mainContact = contact;
        }
        break;
    }
  }

  public get account(): Ref<Customer | null> {
    return this.accountData;
  }

  public get accountGuid(): ComputedRef<string | undefined> {
    return computed(() => this.tokenData.value?.uid_g);
  }

  public get isLoyaltyParticipant(): ComputedRef<boolean> {
    return computed(() => this.tokenData.value?.loyalty === true);
  }

  public get customerGuid(): ComputedRef<string | undefined> {
    return computed(() => this.tokenData.value?.cid);
  }

  public get isAuthenticated(): Ref<boolean> {
    return computed(() => this.accountData.value !== null);
  }

  public get externalAuthBased(): ComputedRef<boolean> {
    return computed(() => this.tokenData.value?.external_auth === true);
  }

  public get isReady(): Ref<boolean> {
    return this.initDone;
  }

  public hasRole(role: AppRoles): boolean {
    return this.hasRoleRef(role).value;
  }

  public hasRoleRef(role: AppRoles): Ref<boolean> {
    return computed(() => this.isAuthenticated.value &&
      ((this.userRoles.value.RESELLER_ADMIN ?? false) || (this.userRoles.value[role] ?? false)));
  }

  private clearAccountData(): void {
    if (this.refreshTokenInterval) {
      clearInterval(this.refreshTokenInterval);
      this.refreshTokenInterval = null;
    }

    this.token.value = null;
    this.tokenData.value = null;
    this.accountData.value = null;

    // Clear Sentry user information
    Sentry.setUser(null);

    removeFromLocalStorage(tokenStorageKey);
    removeFromLocalStorage(ssoIdTokenHintStorageKey);
  }

  private async loadAccountData(token: string, validateToken = false, retrieveAccountData = true): Promise<void> {
    this.token.value = token;
    this.tokenData.value = jwtDecode<TokenData>(token);

    // Setup Sentry user information
    Sentry.setUser({
      id: String(this.tokenData.value?.uid ?? 0),
      username: this.tokenData.value?.username,
    });

    if (this.tokenData.value.exp <= (Date.now() / 1000)) {
      console.warn('Token expired', this.tokenData.value.exp, Date.now() / 1000);
      this.clearAccountData();
      return;
    }

    // Validate token
    if (validateToken) {
      try {
        await getResponseObject(async () => {
          if (!this.validationApi) {
            throw new Error('Token API not available!');
          }

          return await this.validationApi().validateToken();
        });
      } catch (e) {
        console.warn('Token invalid');
        this.clearAccountData();
        return;
      }
    }

    // Store token in local storage
    setInLocalStorage(tokenStorageKey, this.token.value);

    if (retrieveAccountData) {
      // Retrieve account details
      const response = await getResponseObject(async () => {
        if (!this.customerApi) {
          throw new Error('Customer account API not available!');
        }

        if (!this.tokenData.value) {
          throw new Error('Invalid token data!');
        }

        return await this.customerApi().getCustomer({
          customerId: this.tokenData.value.cid,
        });
      });

      if (!response) {
        throw new Error('Customer account not found');
      }

      this.accountData.value = response;
    }

    // Start refresh interval
    if (!this.refreshTokenInterval) {
      this.refreshTokenInterval = setInterval(() => this.tryRefresh(), REFRESH_INTERVAL);
    }
  }

  private async tryRefresh(ignoreExpiration = false, loadAccountData = false, showRefreshError = true): Promise<boolean> {
    if (!this.token.value) {
      console.warn('Token refresh not possible');
      return false;
    }

    if (!ignoreExpiration) {
      const now = Date.now() / 1000;
      const expiry = (this.tokenData.value?.exp ?? 0) - (TOKEN_AGE_BEFORE_REFRESH / 1000);
      if (this.tokenData.value && expiry > now) {
        console.debug('Token refresh not required', expiry, now, now - expiry);
        return false;
      }
    }

    // Refresh token
    try {
      const response = await getResponseObject(async () => {
        if (!this.ssoInteractionApi) {
          throw new Error('SSO interaction API not available');
        }

        return await this.ssoInteractionApi().refreshToken({
          headers: {
            // Set explicit token lifetime
            'X-Token-Lifetime': TOKEN_LIFETIME,
            'X-Token-Include-Roles': true,
          },
        });
      });

      if (response) {
        await this.loadAccountData(response.token, false, loadAccountData);
        console.info('Token refreshed!');
        return true;
      }
    } catch (e) {
      this.clearAccountData();
      this.eventBus.emit(NavigateHome);
      if (showRefreshError) {
        this.modals.error({
          title: 'auth.refresh-failed',
          content: 'auth.refresh-failed-text',
        });
      }
    }

    return true;
  }
}
