import {PageTitleEvent} from '@/events';
import {AppRoles} from '@plugins/auth/AppRoles';
import {useAuthenticator} from '@plugins/auth/auth';
import type Authenticator from '@plugins/auth/Authenticator';
import {type AppEmitter, useEventbus} from '@plugins/eventbus/eventbus';
import {AppFeatures} from '@plugins/features/AppFeatures';
import {type FeatureService, useFeatures} from '@plugins/features/features';
import {type ModalInterface, useModal} from '@plugins/modal/modal';
import {type App} from 'vue';
import {createRouter, createWebHistory, type RouteRecordRaw} from 'vue-router';
import LoyaltyPage from './pages/account/LoyaltyPage.vue';
import HomePage from './pages/HomePage.vue';
import LogoutPage from './pages/LogoutPage.vue';
import NotFoundPage from './pages/NotFoundPage.vue';
import {determineAppVersion} from './utils';

export const RouteLoading = Symbol('[router]: route loading');
export const RouteLoaded = Symbol('[router]: route loaded');
export const NavigateHome = Symbol('[router]: navigate home');

let eventBus: AppEmitter;
let modals: ModalInterface;
let features: FeatureService;
let authenticator: Authenticator;

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
async function asyncImportWrapper(promise: Promise<any>) {
  return promise.catch(async () => {
    try {
      await determineAppVersion(modals);
    } catch (e) {
      // Show general error modal
      modals.generalError();
    } finally {
      // Notify route loaded to remove loader screen
      eventBus.emit(RouteLoaded);
    }
  });
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'homepage',
    component: HomePage,
    meta: {
      public: true,
      title: 'general.home',
    },
  },
  {
    path: '/account/activate',
    component: () => asyncImportWrapper(import('./pages/account/ActivatePage.vue')),
    meta: {
      public: true,
      title: 'auth.activate-account.title',
    },
  },
  {
    path: '/account/password/reset',
    name: 'reset-password',
    component: () => asyncImportWrapper(import('./pages/account/ResetPasswordPage.vue')),
    meta: {
      public: true,
      title: 'auth.reset-password.title',
    },
  },
  {
    path: '/account/password/set',
    component: () => asyncImportWrapper(import('./pages/account/SetPasswordPage.vue')),
    meta: {
      public: true,
      title: 'auth.set-password.title',
    },
  },
  {
    path: '/account/change-password',
    name: 'change-password',
    component: () => asyncImportWrapper(import('./pages/account/ChangePasswordPage.vue')),
    meta: {
      title: 'user-management.title.change-password',
    },
  },
  {
    path: '/account/api-tokens',
    name: 'account-api-tokens',
    component: () => asyncImportWrapper(import('./pages/account/ManageApiTokensPage.vue')),
    meta: {
      title: 'user-management.title.manage-api-tokens',
    },
  },
  {
    path: '/account/loyalty',
    name: 'loyalty',
    component: LoyaltyPage,
    meta: {
      title: 'loyalty._',
    },
  },
  {
    path: '/apply/:customerType?',
    name: 'apply',
    component: () => asyncImportWrapper(import('./pages/apply/ApplyPage.vue')),
    props: true,
    meta: {
      public: true,
      title: 'apply.title._',
    },
  },
  {
    path: '/msa/signed',
    component: () => asyncImportWrapper(import('./pages/msa/MsaSignedPage.vue')),
    meta: {
      public: true,
      title: 'msa.signed.title',
    },
  },
  {
    path: '/company',
    name: 'my-company',
    component: () => asyncImportWrapper(import('./pages/company/MyCompanyPage.vue')),
    meta: {
      title: 'company.title.my',
    },
    children: [
      {
        path: 'contact/:type',
        name: 'edit-company-contact',
        component: () => asyncImportWrapper(import('./pages/company/children/EditContactPage.vue')),
        props: true,
        meta: {
          role: AppRoles.RESELLER_ADMIN,
          title: 'contact.title.edit',
        },
      },
      {
        path: 'edit/address',
        name: 'edit-company-address',
        component: () =>
          asyncImportWrapper(import('./pages/company/children/EditCompanyAddressPage.vue')),
        meta: {
          role: AppRoles.RESELLER_ADMIN,
          title: 'company.edit.address',
        },
      },
      {
        path: 'edit/iban',
        name: 'edit-company-iban',
        component: () => asyncImportWrapper(import('./pages/company/children/EditCompanyIbanPage.vue')),
        meta: {
          role: AppRoles.RESELLER_ADMIN,
          title: 'company.title.edit-iban',
        },
      },
    ],
  },
  {
    path: '/company/agreements',
    name: 'my-company-agreements',
    component: () => asyncImportWrapper(import('./pages/company/MyCompanyAgreements.vue')),
    meta: {
      role: AppRoles.RESELLER_ADMIN,
      title: 'company.title.agreements',
    },
  },
  {
    path: '/postal-code-check',
    name: 'postal-code-check',
    component: () => asyncImportWrapper(import('./pages/postal_code_check/PostalCodeCheckPage.vue')),
    meta: {
      public: true,
      feature: AppFeatures.postalCodeCheck,
      title: 'postal-code-check.title',
    },
  },
  {
    path: '/lines',
    name: 'my-lines',
    component: () => asyncImportWrapper(import('./pages/lines/MyLinesPage.vue')),
    meta: {
      feature: AppFeatures.myLines,
      title: 'lines.title.overview',
    },
    children: [
      {
        path: 'detail/:id',
        name: 'my-line-details',
        component: () => asyncImportWrapper(import('./pages/lines/children/MyLineDetailsChildPage.vue')),
        props: true,
        meta: {
          title: 'lines.title.details',
        },
      },
    ],
  },
  {
    path: '/invoices',
    name: 'my-invoices',
    component: () => asyncImportWrapper(import('./pages/invoices/MyInvoicesPage.vue')),
    meta: {
      feature: AppFeatures.myInvoices,
      role: AppRoles.RESELLER_FINANCE,
      title: 'invoices.title.overview',
    },
    children: [
      {
        path: '/invoice/:number',
        name: 'my-invoice-details',
        component: () => asyncImportWrapper(import('./pages/invoices/children/InvoiceDetails.vue')),
        props: true,
        meta: {
          title: 'invoices.title.details',
        },
      },
    ],
  },
  {
    path: '/orders',
    name: 'my-orders',
    component: () => asyncImportWrapper(import('./pages/orders/MyOrdersPage.vue')),
    meta: {
      feature: AppFeatures.myOrders,
      role: AppRoles.RESELLER_ORDER,
      title: 'orders._',
    },
    children: [
      {
        path: 'line/new',
        name: 'new-line-order',
        component: () => asyncImportWrapper(import('./pages/orders/children/CreateLineOrderPage.vue')),
        props: true,
        meta: {
          title: 'orders.title.new-line-order',
        },
      },
      {
        path: 'rack/new/:chosenSite?',
        name: 'new-rack-order',
        component: () => asyncImportWrapper(import('./pages/orders/children/CreateRackOrderPage.vue')),
        props: true,
        meta: {
          title: 'orders.title.new-rack-order',
        },
      },
      {
        path: 'dc-connection/new/:chosenPackage?',
        name: 'new-dc-connection-order',
        component: () => asyncImportWrapper(import('./pages/orders/children/CreateDcConnectionOrderPage.vue'),
        ),
        props: true,
        meta: {
          title: 'orders.title.new-dc-connection-order',
        },
      },
      {
        path: 'wdm/new/:chosenPackage?',
        name: 'new-wdm-order',
        component: () => asyncImportWrapper(import('./pages/orders/children/CreateWdmOrderPage.vue')),
        props: true,
        meta: {
          title: 'orders.title.new-wdm-order',
        },
      },
    ],
  },
  {
    path: '/quotations',
    name: 'my-quotations',
    component: () => asyncImportWrapper(import('./pages/quotations/MyQuotationsPage.vue')),
    meta: {
      feature: AppFeatures.quotation,
      role: AppRoles.RESELLER_ORDER,
      title: 'quotations._',
    },
    children: [
      {
        path: ':action/:quotationId',
        name: 'continue-with-quotation',
        component: () => asyncImportWrapper(import('./pages/quotations/children/ContinueWithQuotationPage.vue'),
        ),
        props: true,
        meta: {
          title: 'quotations.title.complete',
        },
      },
    ],
  },
  {
    path: '/racks',
    name: 'my-racks',
    component: () => asyncImportWrapper(import('./pages/racks/MyRacksPage.vue')),
    meta: {
      feature: AppFeatures.rackSpace,
      role: AppRoles.RESELLER_ORDER,
      title: 'racks._',
    },
    children: [
      {
        path: 'rack/:id/power/dashboard',
        name: 'rack-power-dashboard',
        component: () => asyncImportWrapper(import('./pages/racks/children/RackPowerPage.vue')),
        props: true,
        meta: {
          title: 'racks.title.rack-dashboard',
        },
      },
      {
        path: 'shared-colo/:id/power/dashboard',
        name: 'shared-colo-power-dashboard',
        component: () => asyncImportWrapper(import('./pages/racks/children/SharedColoPowerPage.vue')),
        props: true,
        meta: {
          title: 'racks.title.shared-colo-dashboard',
        },
      },
    ],
  },
  {
    path: '/dc-connections',
    name: 'my-dc-connections',
    component: () => asyncImportWrapper(import('./pages/dc_connections/MyDcConnectionsPage.vue')),
    meta: {
      feature: AppFeatures.dcConnections,
      role: AppRoles.RESELLER_ORDER,
      title: 'dc-connections._',
    },
  },
  {
    path: '/wdm',
    name: 'my-wdm',
    component: () => asyncImportWrapper(import('./pages/wdm/MyWdmPage.vue')),
    meta: {
      feature: AppFeatures.wdm,
      role: AppRoles.RESELLER_ORDER,
      title: 'wdm._',
    },
  },
  {
    path: '/ip-management',
    name: 'ip-management',
    component: () => asyncImportWrapper(import('./pages/ip_management/IpManagementPage.vue')),
    meta: {
      feature: AppFeatures.ipManagement,
      role: AppRoles.RESELLER_SERVICE_DESK,
      title: 'ip-management._',
    },
    children: [
      {
        path: 'prefix/:id',
        name: 'ip-management-prefix',
        component: () => asyncImportWrapper(import('./pages/ip_management/children/ManagePrefix.vue'),
        ),
        props: true,
        meta: {
          title: 'ip-management.prefix.details',
        },
      },
    ],
  },
  {
    path: '/user-management',
    name: 'user-management',
    component: () => asyncImportWrapper(import('./pages/user_management/UserManagementPage.vue')),
    meta: {
      feature: AppFeatures.userManagement,
      role: AppRoles.RESELLER_ADMIN,
      title: 'user-management._',
    },
    children: [
      {
        path: 'account/:id',
        name: 'user-management-edit-account',
        component: () => asyncImportWrapper(import('./pages/user_management/children/EditAccount.vue')),
        props: true,
        meta: {
          title: 'user-management.title.edit-account',
        },
      }, {
        path: 'account/create',
        name: 'user-management-create-account',
        component: () => asyncImportWrapper(import('./pages/user_management/children/CreateAccount.vue')),
        meta: {
          title: 'user-management.title.create-account',
        },
      }, {
        path: 'tokens/:id',
        name: 'user-management-api-tokens',
        component: () => asyncImportWrapper(import('./pages/user_management/children/ManageApiTokens.vue')),
        props: true,
        meta: {
          title: 'user-management.title.manage-api-tokens',
        },
      },
    ],
  },
  {
    path: '/acronis',
    name: 'acronis',
    component: () => asyncImportWrapper(import('./pages/acronis/AcronisPage.vue')),
    meta: {
      feature: AppFeatures.acronis,
      role: AppRoles.RESELLER_ORDER,
      title: 'acronis._',
    },
  },
  {
    name: '101010tv-accounts',
    path: '/tv-accounts',
    component: () => asyncImportWrapper(import('./pages/101010tv/accounts/AccountsPage.vue')),
    meta: {
      feature: AppFeatures.tenTenTenTv,
      role: AppRoles.RESELLER_SERVICE_DESK,
      title: '101010tv.accounts._',
    },
    children: [
      {
        path: ':accountId',
        name: '101010tv-account',
        props: true,
        component: () => asyncImportWrapper(import('./pages/101010tv/accounts/children/AccountDetailsPage.vue'),
        ),
        meta: {
          title: '101010tv.accounts.account-details',
        },
      }, {
        path: 'create',
        name: '101010tv-add-account',
        component: () => asyncImportWrapper(import('./pages/101010tv/accounts/children/AddAccountPage.vue'),
        ),
        meta: {
          title: '101010tv.accounts.add',
        },
      },
    ],
  },
  {
    path: '/unlock',
    component: () => asyncImportWrapper(import('./pages/UnlockFeaturesPage.vue')),
    meta: {
      public: true,
    },
  },
  {
    path: '/token/login',
    component: () => asyncImportWrapper(import('./pages/TokenLoginPage.vue')),
    meta: {
      public: true,
    },
  },
  {
    path: '/sso/login',
    name: 'sso_login',
    component: () => asyncImportWrapper(import('./pages/SsoLoginPage.vue')),
    meta: {
      public: true,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutPage,
    meta: {
      title: 'auth.logout',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFoundPage,
    meta: {
      public: true,
    },
  },
];

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export default function(app: App): void {
  // Retrieve the event bus instance for use by the router guards
  app.runWithContext(() => {
    eventBus = useEventbus();
    modals = useModal();
    features = useFeatures();
    authenticator = useAuthenticator();
  });

  // Page loader events
  router.beforeEach((to, from, next) => {
    // The authenticator must be ready for these checks to be run
    if (authenticator.isReady.value) {
      // Check for authentication. All routes are authenticated unless marked as public
      if (to.meta.public !== true && !authenticator.isAuthenticated.value) {
        console.debug('Not authenticated', to);
        return;
      }

      // Test whether the configured role has been granted
      if (to.meta.role && !authenticator.hasRole(to.meta.role as AppRoles)) {
        console.debug('Role not granted', to.meta.role, to);
        return;
      }
    }

    // Test whether the configured feature is enabled
    if (to.meta.feature && !features.featureEnabled(to.meta.feature as AppFeatures)) {
      console.debug('Feature disabled', to.meta.feature, to);
      return;
    }

    // In case of async component load, emit route loading signal
    if (typeof to.matched[0]?.components?.default === 'function') {
      eventBus.emit(RouteLoading);
    }

    next();
  });

  router.beforeResolve((to, from, next) => {
    eventBus.emit(RouteLoaded);

    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    eventBus.emit(PageTitleEvent, nearestWithTitle ? nearestWithTitle.meta.title ?? null : null);

    next();
  });

  eventBus.on(NavigateHome, () => {
    router.push({name: 'homepage'});
  });

  app.use(router);
}
