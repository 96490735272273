import {isHmr} from '@/utils';
import {HttpDisabled500Handling, HttpInstance} from '@plugins/injections';
import {useModal} from '@plugins/modal/modal';
import axios, {type AxiosInstance} from 'axios';
import {type App, inject, ref} from 'vue';
import {getCurrentLocale} from '../i18n/i18n';

function buildInstance(withCredentials?: boolean): AxiosInstance {
  return axios.create({
    withCredentials,
  });
}

export function useHttp(cleanInstance = false): AxiosInstance {
  if (cleanInstance) {
    return buildInstance();
  }

  const instance = inject(HttpInstance);
  if (!instance) {
    throw new Error('HTTP instance not available, did you enable the http plugin?');
  }

  return instance;
}

export function use500HandlerDisabler(): <T>(callback: () => Promise<T>) => Promise<T | undefined> {
  const disabler = inject(HttpDisabled500Handling);

  if (!disabler) {
    throw new Error('HTTP 500 handler disabler not available, did you enable the http plugin?');
  }

  return async (callback) => {
    if (disabler.value !== null) {
      console.warn('HTTP 500 handler was already disabled', disabler.value);
    }

    disabler.value = true;

    try {
      return await callback();
    } catch (e) {
      return undefined;
    } finally {
      disabler.value = null;
    }
  };
}

export function sendBeacon(url: string, data?: BodyInit): void {
  if (typeof window.navigator.sendBeacon === 'function') {
    window.navigator.sendBeacon(url, data);
  } else {
    // Possibly unreliable, but better than nothing if the beacon API is not available
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    if (data) {
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.send(JSON.stringify(data));
    } else {
      xhr.send();
    }
  }
}

export default function(app: App): void {
  // Retrieve the modal instance for use by the interceptors
  const modals = app.runWithContext(() => useModal());
  const currentLocale = app.runWithContext(() => getCurrentLocale());
  const instance = buildInstance();
  const disabled500Handling = ref<string | true | null>(null);

  // Install the generic Accept-language interceptor, which sets the current locale
  instance.interceptors.request.use(config => {
    config.headers['Accept-Language'] = currentLocale.value;

    if (isHmr() && config.url && document.cookie.includes('XDEBUG')) {
      try {
        const url = new URL(config.url);
        url.searchParams.append('XDEBUG_TRIGGER', '1');
        config.url = url.toString();
      } catch (e) {
        // Ignore
      }
    }

    if (disabled500Handling.value === true) {
      console.warn('500 error handling disabled for', config.url);
      disabled500Handling.value = config.url ?? null;
    }

    return config;
  });

  // Install the generic error interceptor
  instance.interceptors.response.use(
    response => response,
    error => {
      if (!error.response) {
        modals.generalError();
      } else if (error.response && [429, 500].includes(error.response.status)) {
        if (disabled500Handling.value === error.config.url) {
          console.warn('500 error handling skipped', error.config.url);
          disabled500Handling.value = null;
        } else {
          modals.error({
            title: `api.${error.response.status}.title`,
            content: `api.${error.response.status}.text`,
          });
        }
      }

      return Promise.reject(error);
    });

  app.provide(HttpInstance, instance);
  app.provide(HttpDisabled500Handling, disabled500Handling);
}
