<template>
  <div
    :id="id"
    aria-hidden="true"
    :aria-labelledby="labelId"
    class="modal fade"
    role="dialog"
    tabindex="-1">
    <div
      class="modal-dialog"
      :class="modalClass"
      role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            :id="labelId"
            class="modal-title">
            <Translated
              v-if="translate"
              :msg="title"
              :props="titleProps"/>
            <span
              v-else
              v-text="title"/>
          </h5>
        </div>
        <div
          class="modal-body"
          :class="content ? 'd-flex' : ''">
          <slot name="content">
            <div
              v-if="icon"
              class="text-center me-3 align-self-center"
              :class="iconClass">
              <font-awesome-icon
                :icon="icon"
                size="3x"/>
            </div>
            <div>
              <Translated
                v-if="translate"
                :msg="content"
                :props="contentProps"/>
              <span
                v-else
                v-text="content"/>
            </div>
          </slot>
        </div>
        <div class="modal-footer">
          <slot
            name="footer"
            v-bind="{hide}">
            <button
              v-if="type === 'confirm'"
              class="btn btn-text"
              type="button"
              @click="onCancel">
              <template v-if="cancelIcon">
                <font-awesome-icon
                  fixed-width
                  :icon="cancelIcon"/>
              </template>
              <Translated :msg="cancelText"/>
            </button>

            <button
              class="btn btn-primary"
              type="button"
              @click="onOk">
              <template v-if="okIcon">
                <font-awesome-icon
                  fixed-width
                  :icon="okIcon"/>
              </template>
              <Translated :msg="okText"/>
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Translated from '@plugins/i18n/components/Translated.vue';
import BsModal from 'bootstrap/js/dist/modal';
import {v4} from 'uuid';
import {computed, defineComponent, onMounted, type PropType, toRefs} from 'vue';

export default defineComponent({
  name: 'Modal',
  components: {Translated},
  props: {
    id: {
      type: String,
      default: () => v4(),
    },
    title: {
      type: String,
      required: true,
    },
    titleProps: {
      type: Object,
    },
    content: {
      type: String,
      default: '',
    },
    contentProps: {
      type: Object,
    },
    translate: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String as PropType<'ok'|'confirm'>,
      default: 'ok',
    },
    okText: {
      type: String,
      default: 'button.ok',
    },
    okIcon: {
      type: [String, Array],
      default: 'check',
    },
    okCallback: Function,
    cancelText: {
      type: String,
      default: 'button.cancel',
    },
    cancelIcon: {
      type: [String, Array],
      default: 'times',
    },
    cancelCallback: Function,
    hideCallback: Function,
    icon: {
      type: [String, Array],
    },
    iconVariant: {
      type: String,
    },
    modalSize: {
      type: String,
    },
    showOnMount: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['hidden'],
  setup(props, context) {
    const propRefs = toRefs(props);
    let modalRef: BsModal | null = null;

    const show = () => modalRef?.show();
    const hide = () => modalRef?.hide();

    onMounted(() => {
      const element = document.getElementById(propRefs.id.value);
      if (!element) {
        console.error('Modal element not found');
        return;
      }

      modalRef = new BsModal(element);
      element.addEventListener('hidden.bs.modal', () => context.emit('hidden'));
      element.addEventListener('hide.bs.modal', () => props.hideCallback ? props.hideCallback() : true);

      if (props.showOnMount) {
        show();
      }
    });

    return {
      labelId: computed(() => propRefs.id.value + '-l'),
      modalClass: computed(() => propRefs.modalSize?.value ? `modal-${propRefs.modalSize.value}` : ''),
      iconClass: computed(() => {
        if (!propRefs.icon?.value || !propRefs.iconVariant?.value) {
          return '';
        }

        return `text-${propRefs.iconVariant.value}`;
      }),
      onOk: async () => {
        const result = props.okCallback ? await props.okCallback() : true;

        if (result !== false) {
          modalRef?.hide();
        }
      },
      onCancel: async () => {
        const result = props.cancelCallback ? await props.cancelCallback() : true;

        if (result !== false) {
          modalRef?.hide();
        }
      },
      show,
      hide,
    };
  },
});
</script>
